
.verein {
  margin-top: 85px; }


#trauerRahmen {
  border: 20px double #514f4f;
  background: #ffffff;
  text-align: center;
  vertical-align: center;
  width: 250px;
  height: 452px; }



.dekstopAntrag, .mobileAntrag {
  display: none;
  width: 100%; }

.dekstopAntrag {
  @media all and (min-width: 1025px) {
    display: inline-block; } }

.mobileAntrag {
  @media all and (min-width: 375px) and (max-width: 1024px) {
    display: inline-block; } }


.floatingList {
  list-style: none;

  li {
    float: left; } }

article {
  margin: 10px;
  padding: 3px;
  text-align: justify;
  margin-top: 50px;
  max-width: 850px; }

a {
  text-decoration: none;
  font-weight: bold;
  color: #282c34; }

aside {
  margin: 10px;
  text-align: justify; }

.content {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 3px;
  margin-top: 120px; }


.logoWappen {
  width: 70px;
  height: auto;
  padding: 3px;
  margin: 3px; }

body {
  color: #333;
  font-family: Tahoma, "Lucida Sans", Verdana, Helvetica, Arial, sans-serif; }

#bg {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center right;
  @media screen and (max-width: 415px) {
    background-position: center right; } }

#bg.gedenken {
  background-image: url("images/fiedhof.jpg"); }

.hohenfels {
  background-image: url("images/allgemein/hohenfels_turm1a.jpg"); }

.bayernFlagge {
  background-image: url("images/allgemein/bayern-flagge1a.jpg");
  @media screen and (max-width: 415px) {
    background-size: 80% !important;
    background-position: center bottom !important; } }

.bayernFlagge2 {
  background-image: url("images/allgemein/bayern-flagge1a.jpg");
  @media screen and (max-width: 415px) {
    background: none !important; } }

.weihnachten1 {
  background-image: url("images/weihnachten/bild4.jpg");
  @media screen and (max-width: 415px) {
    background-size: 150% !important;
    background-position: left bottom !important; } }

.fasching {
  background-image: url("images/termine/fasching1.jpg");
  @media screen and (max-width: 415px) {
    background-size: 150% !important;
    background-position: left bottom !important; } }

.sommergrillen {
  background-image: url("images/termine/grillen1.jpg");
  @media screen and (max-width: 415px) {
    background-size: 150% !important;
    background-position: left bottom !important; } }

.allianzArena {
  background-image: url("images/alianz-arena.jpg"); }

.allianzArena_rot {
  background-image: url("images/termine/allianz-arena3.jpg");
  @media screen and (max-width: 415px) {
    background-size: 150% !important;
    background-position: left bottom !important; } }

.stadionAllianzArena {
  background-image: url("images/termine/stadion1.jpg"); }


.btnSubmitSave {
  color: #fff !important;
  background-color: rgb(32, 79, 4) !important;
  border: solid 2px #666 !important;
  radius: 5 !important;
  &:hover {
    background-color: rgb(23, 194, 27) !important; } }

.btnSubmitAddNew {
  color: #fff !important;
  background-color: rgb(33, 124, 201) !important;
  border: solid 2px #666 !important;
  radius: 5 !important;
  &:hover {
    background-color: rgb(232, 8, 8) !important; } }

.btnSubmitCancel {
  color: #fff !important;
  background-color: rgb(182, 5, 5) !important;
  border: solid 2px #666 !important;
  radius: 5 !important;
  &:hover {
    background-color: rgb(245, 90, 21) !important; } }



.menuTabs {
  color: #fff; }

.adminTabs {
  color: #fff; }

.articleTrauerContent {
  min-height: 250px;
  border-style: double;
  border-width: 6px;
  border-color: #666;
  background: rgb(0, 0, 0);
  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(102, 102, 102, 1) 1.5%, rgba(221, 221, 221, 1) 5%, rgba(221, 221, 221, 1) 95%, rgba(102, 102, 102, 1) 98.5%, rgba(0, 0, 0, 1) 100%);
  padding-left: 25px;
  padding-right: 25px; }

.trauerContent {
  border-width: 7px;
  border-color: rgb(169, 169, 169);
  border-style: double;
  background-color: rgb(180, 180, 180);
  background-image: url("./images/kerze.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  text-align: justify;
  padding: 15px;
  color: rgb(255, 255, 255); }

.editButtonRed {
  width: 25px;
  height: 25px;
  background-image: url("images/edit.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  margin: 0px 0px 0px 3px;
  padding: 0;
  cursor: pointer;
  position: relative;
  top: 6px; }

.eventRegistrations {
  clear: both;
  font-size: large;
  width: 100%;
  li {
    margin: 5px;
    select {
      width: 93%;
      font-size: large; }
    span {
      width: 48%;
      display: inline-block;
      margin-right: 2px; }
    input {
      &[type="text"],
      &[type="date"] {
        width: 47%;
        margin-left: 2px;
        font-size: large;
        display: inline-block; } } } }

ul {
  list-style: none;
  margin: 0px;
  padding: 10px; }

.list {
  li {
    clear: both;
    margin: 1px !important; }
  li:before {
    content: "\25B9" " "; } }

.floatList {
  max-width: 150vw;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  li {
    max-width: 650px;
    float: left;
    margin: 10px;
    margin-right: 0;
    padding: 0; } }

.appBarSubMenu {
  position: fixed;
  margin-top: 85px;
  color: #fff !important;
  background: linear-gradient(180deg, rgba(166, 0, 0, 1) 90%, rgba(166, 0, 0, 1) 100%); }

.infoBox {
  border: 1px solid #761c19;
  background-color: #87ceeb;
  color: #151515;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  overflow: hidden; }

p.underline {
  text-decoration: underline; }

.appBarAdminMenu {
  position: fixed;
  margin-top: 170px;
  color: #fff !important;
  background: linear-gradient(180deg, rgba(33, 66, 99, 1) 90%, rgba(33, 66, 99, 1) 100%); }


.termine {
  margin-top: 85px; }


.gebCard {
  width: 90%;
  height: 100%;
  max-width: 600px;
  max-height: 362px;
  border: solid 1px #666;
  /* Verlauf */
  background: #CE0808;
  background: -moz-linear-gradient(-45deg, #CE0808 0%, #FFFFFF 50%, #66A1FF 100%);
  background: -webkit-linear-gradient(-45deg, #CE0808 0%, #FFFFFF 50%, #66A1FF 100%);
  background: linear-gradient(135deg, #CE0808 0%, #FFFFFF 50%, #66A1FF 100%);
  /* Hintergrundbild */
  /* https://pixabay.com/de/vectors/jahrestag-luftballons-flasche-eimer-157248/ */
  background-image: url("./images/allgemein/geburtstagskarte.jpg");
  vertical-align: center;
  horiz-align: center;
  padding: 10px;
  padding-top: 80px;
  -webkit-box-shadow: 5px 5px 10px 1px #000000;
  box-shadow: 5px 5px 10px 1px #000000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  p {
    margin: 100px; }
  h2 {
    margin-left: 100px; }
  i {
    margin-left: 120px; }
  @media screen and (max-width: 415px) {
    padding-top: 50px;
    background-position: center top;
    max-width: 400px;
    max-height: 562px;
    p {
      margin: 40px; }
    h2 {
      margin-left: 40px; }
    i {
      margin-left: 50px; } } }

.spieltag, .notes {
  margin: 20px;
  width: 300px;
  height: 320px;
  padding: 25px 15px;
  background-color: #fefabc;
  background-image: linear-gradient(150deg, #efec88 0%, #fefabc 100%);
  background-image: url("images/termine/stadion3.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  border: 1px solid #cccccc;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  transform: rotate(2deg);
  border-bottom-right-radius: 60px 5px;
  ::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.025);
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0;
    height: 30px;
    z-index: -1; }
  ::after {
    content: " ";
    position: absolute;
    bottom: 0;
    right: -4px;
    width: 100%;
    height: 140px;
    background-image: linear-gradient(173deg, rgba(0, 0, 0, 0) 92%, rgba(0, 0, 0, 0.4) 100%);
    transform: rotate(4deg);
    z-index: -1;
    filter: blur(2px); }
  h4 {
    font-family: 'Arial', cursive;
    font-size: 1.4em;
    text-align: center;
    margin: 0.5em 0 0;
    line-height: 1em;
    font-weight: bold; }
  p {
    margin-top: 20px; }
  b.result {
    color: #fff;
    font-size: 72px;
    display: inline-block;
    line-height: 72px;
    margin-top: 30px;
    margin-left: 90px;
    text-shadow: 2px 2px #666; } }

.notes {
  background-image: none; }

div[role='tabpanel'] {
  width: 100% !important; }

.tabContent {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border: solid 1px rgba(153, 153, 153, 1);
  border-top-color: #FFFFFF;
  display: none;
  clear: both; }

.activeTab {
  display: block; }


.customTabControl {
  background: linear-gradient(0deg, rgba(153, 153, 153, 1) 0%, rgba(255, 255, 255, 1) 9%, rgba(255, 255, 255, 1) 100%);
  margin: 0;
  padding: 3px;
  list-style: none;
  overflow: hidden;
  li {
    float: left;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(153, 153, 153, 1);
    background-color: rgba(255, 255, 255, 1);
    color: #000000;
    margin-right: 5px;
    padding: 3px 5px;
    margin-bottom: -3px;
    border-radius: 5px 5px 0px 0px;
    font-size: 14px;
    border-bottom-color: rgba(153, 153, 153, 1);
    cursor: pointer;
    &:hover {
      background: linear-gradient(0deg, rgba(153, 153, 153, 1) 0%, rgba(255, 255, 255, 1) 100%); } } }

li {
  &.selectedTab {
    border-bottom-color: rgba(255, 255, 255, 1) !important; } }

.leereVorlage {
  display: none; }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5) /* Halbtransparenter Hintergrund */;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000 /* Höher als andere Elemente */; }


.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 400px;
  max-width: 90%;
  z-index: 1001; }


.modal h2 {
  margin-top: 0; }


.modal button {
  margin-right: 10px; }




.toolBar {
  background: linear-gradient(180deg, rgba(59,59,59,1) 90%, rgba(166,0,0,1) 100%);
  text-align: center; }

.appTitle {
  flexGrow: 1;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  @media only screen and (max-width: 790px) {
    text-overflow: ellipsis; } }
